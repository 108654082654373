import { radio, T_popup } from '@/state/radio';
import { useTranslation } from 'next-i18next';
import { useSnackbar } from 'notistack';
import React, { FC, HTMLAttributes, memo, useEffect } from 'react';
import wc from 'words-count';

export interface I_Popup_control extends HTMLAttributes<HTMLDivElement> {}

function duration_calc(full_text: string): number {
  const ms = wc(full_text) * 100;
  const min = 1500;
  if (ms < min) {
    return min;
  }

  return ms;
}

const Popup_control: FC<I_Popup_control> = memo<I_Popup_control>(() => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    radio.popup.subscribe((v: string | T_popup) => {
      let opt: T_popup = v as any;
      if (typeof v === 'string') {
        opt = { title: v };
      }
      const { key, data, type, duration, position } = opt;
      let { detail } = opt;
      const path = `error.${data?.ns ?? 'base'}:${key}`;
      const title = (key ? t(`${path}.title`, { defaultValue: '' }).toString() : opt.title ?? '').trim() as string;
      const desc = `${t(`${path}.desc`, { defaultValue: '' }).toString()}`.trim();
      detail = `${key ? title ?? '' : ''} ${detail ?? ''} ${key ? opt.title : ''}`.trim();
      let autoHideDuration = duration ?? duration_calc(title + detail);
      if (type === 'error') {
        if (autoHideDuration < 3000) {
          autoHideDuration = 3000;
        }
      }
      enqueueSnackbar(
        detail ? (
          <div>
            {title && <strong>{title}</strong>}
            {desc && <div>{desc}</div>}
            {detail && <div>{detail}</div>}
          </div>
        ) : (
          title
        ),
        {
          variant: type,
          autoHideDuration,
          anchorOrigin: {
            vertical: position?.vertical ?? 'top',
            horizontal: position?.horizontal ?? 'center',
          },
        },
      );
    });
  }, [enqueueSnackbar, t]);

  return <></>;
});

export default Popup_control;
